<!--
  Component for looking up OMW links by appointment ID
-->
<template>
  <div class="container">
    <form>
      <section class="section">
        <div class="container">
          <article class="notification is-info columns">
            <div class="column">
              <p class="has-text-weight-semibold">
                {{ $t('Appointment No.') }}
              </p>
              <b-input
                v-model="apptNumber"
                :disabled="postcode.length > 0"
                is-large
                class="mb-1"
              ></b-input>
              <data-set-selector
                v-model="selectedDataset"
                :include-all-option="false"
              />
              <b-button
                class="ml-1"
                type="is-primary"
                :disabled="disableSubmit"
                :loading="loading"
                @click.prevent="onSubmit()"
              >
                {{ $t('Search') }}
              </b-button>
              <b-button
                class="ml-1"
                type="is-primary"
                :disabled="!matchedAppointments.length"
                @click.prevent="clearData()"
              >
                {{ $t('Clear') }}
              </b-button>
            </div>
            <div class="column">
              <p class="has-text-weight-semibold">
                {{ $t('general-postcode') }}
              </p>

              <b-input
                v-model="postcode"
                class="mb-1"
                :disabled="apptNumber.length > 0"
                is-large
              ></b-input>
              <p v-html="$t('omw-lookup-help')"></p>
            </div>
          </article>
          <p v-if="error" class="has-text-weight-semibold">
            {{ notFoundMsg }}
          </p>
        </div>
      </section>
    </form>
    <omw-lookup-results
      v-if="matchedAppointments.length"
      :matched-appointments="matchedAppointments"
      :loading="loading"
      :dataset="selectedDataset"
      :appt-number-field="searchField"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import { getOmwUrl } from '@/services/omwLookupService.js';
import DataSetSelector from '@/components/shared/DataSetSelector.vue';
import OmwLookupResults from './OmwLookupResults.vue';

export default defineComponent({
  name: 'OMWLookup',
  components: {
    DataSetSelector,
    OmwLookupResults,
  },
  data() {
    return {
      postcode: '',
      apptNumber: '',
      selectedDataset: undefined,
      matchedAppointments: [],
      error: undefined,
      loading: false,
      showTooltip: false,
    };
  },
  computed: {
    disableSubmit() {
      return this.postcode.length < 6 && !this.apptNumber;
    },
    notFoundMsg() {
      const found = this.apptNumber ? this.apptNumber : this.postcode;
      return `No OMW links found for ${found}`;
    },
    searchField() {
      const datasetItem = this.$configData.customer?.datasets?.filter(
        (dataset) => {
          return dataset.id === this.selectedDataset;
        },
      );
      return datasetItem[0].searchField || 'apptNumber';
    },
  },
  watch: {
    selectedDataset: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.clearData();
        }
      },
    },
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true;
        this.error = undefined;
        this.matchedAppointments = [];
        let searchValue;
        let selectedSearchField;
        const autoUpperCase = this.$configData.omwLookup.autoUpperCase;

        if (this.apptNumber) {
          searchValue = this.apptNumber;
          selectedSearchField = this.searchField;
        } else if (this.postcode) {
          searchValue = autoUpperCase
            ? this.postcode.toUpperCase()
            : this.postcode;
          selectedSearchField = 'postalCode';
        }
        this.matchedAppointments = await getOmwUrl(
          this.selectedDataset,
          selectedSearchField,
          searchValue,
          this.searchField,
        );
        if (!this?.matchedAppointments.length) this.error = true;
      } catch (err) {
        this.error = `Something went wrong retrieving the OMW URL`;
      } finally {
        this.loading = false;
      }
    },
    clearData() {
      this.matchedAppointments = [];
    },
  },
});
</script>

<style scoped>
.search-form {
  padding-top: 10px;
  width: 80%;
}

textarea {
  display: block;
  padding: 10px;
  margin: 10px 0 0 -10px;
  width: 340px;
  height: 360px;
  resize: none;
  overflow: auto;
}

.label {
  align-content: flex-start;
}
</style>
