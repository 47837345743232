import axios from 'axios';
import configData from '../config/config.json';
import { getIdToken } from '@/services/auth/index';

const apiClient = axios.create({
  baseURL: configData.omwLookup.baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getIdToken();
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    }
    throw new Error('No access token Found');
  },
  (err) => Promise.reject(err),
);

export const getOmwUrl = async (
  datasetId,
  searchField,
  searchValue,
  identifier,
) => {
  const params = new URLSearchParams();
  params.append('searchField', searchField);
  params.append('searchValue', searchValue);
  params.append('identifier', identifier);

  const options = {
    method: 'GET',
    url: `${configData.omwLookup.path}/${datasetId}`,
    params,
  };
  const { data } = await apiClient(options);
  return data;
};
