<template>
  <b-table
    :key="rerender"
    :data="matchedAppointments"
    :paginated="true"
    :loading="loading"
    default-sort="date"
  >
    <b-table-column v-slot="props" field="date" label="Date" sortable>
      {{ props.row.date }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      :field="apptNumberField"
      label="Identifier"
      sortable
    >
      {{ props.row[apptNumberField] }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="streetAddress"
      label="Address"
      sortable
    >
      {{ props.row.streetAddress }}
    </b-table-column>
    <b-table-column v-slot="props" field="postalCode" label="Postcode" sortable>
      {{ props.row.postalCode }}
    </b-table-column>
    <b-table-column v-slot="props" field="timeSlot" label="Time Slot" sortable>
      {{ props.row.timeSlot }}
    </b-table-column>
    <b-table-column v-slot="props" field="omwUrl" label="On My Way">
      <b-button type="is-primary" @click="openBrowser(props.row.omwUrl)"
        >OMW</b-button
      >
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  name: 'OmwResults',
  props: {
    matchedAppointments: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    dataset: {
      type: String,
      required: true,
    },
    apptNumberField: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rerender: 0,
    };
  },
  methods: {
    openBrowser(url) {
      window.open(
        `${url}?contactCentre=true`,
        '_blank',
        'menubar=no,location=no,status=no',
      );
    },
  },
};
</script>

<style></style>
